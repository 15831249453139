import { Component, OnInit } from '@angular/core';
import { RecordsDataService } from '../records-data.service';
import { ActivatedRoute,Router,ChildActivationEnd } from '@angular/router';
import { map, filter, mergeMap,take } from 'rxjs/operators';
import { SEOService } from '../seo-service.service';

@Component({
  selector: 'app-blogs-page',
  templateUrl: './blogs-page.component.html',
  styleUrls: ['./blogs-page.component.css']
})
export class BlogsPageComponent implements OnInit {
homeData = {};
data = {};
public par;
BlogData:any;
  constructor(public myFirstService: RecordsDataService,public router:Router, public route:ActivatedRoute,private _seoService:SEOService) {
   }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      // console.log(params),
        this.par = params;
      this.myFirstService.getBlogData(this.par.link).subscribe((blog:any) => {
        this.BlogData = blog;
        // console.log(this.BlogData);

        const title = blog[0].Title 
        this._seoService.updateTitle(title);
        // this._seoService.updateOgUrl(event['ogUrl']);
        // //Updating Description tag dynamically with title
        // this._seoService.updateDescription(event['title'] + event['description'])
      });




    });
    
    // this.route.params.subscribe((params) => {
    //   //console.log(params),
    //     this.par = params;

    //   this.myFirstService.getBlogData(this.par.link).subscribe(res => {
    //     this.BlogData = res;
    //     //console.log(this.BlogData);

    //   });
    


    // // });
    // //console.log(this.homeData, 'is working');
    // this.route.params.subscribe((params) => {
    //   //console.log(params);
    //     this.par = params;
    // });
    // this.homeData = this.myFirstService.getData().Blogs;
    // //console.log(this.homeData);
    // this.data = this.homeData[this.par.link];
    // //console.log(this.data, "blog data is received");
  }

}
