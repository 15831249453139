import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RecordsDataService } from './records-data.service';
import { tap, map, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private myFirstService: RecordsDataService, private router: Router) {

  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    return this.myFirstService.User.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        if (!loggedIn) {
          //console.log('access denied')
          this.router.navigate(['/']);
        }
      }));
  }

}
