import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { CarouselModule } from 'ngx-bootstrap/carousel';
// import { RatingModule } from 'ngx-bootstrap/rating';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from './modal/modal.component';
import { CardsComponent } from './cards/cards.component';
// import { FirstPageComponent } from './first-page/first-page.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RecordsDataService } from './records-data.service';
// import { LessonListComponent } from './lesson-list/lesson-list.component';
// import { SubjectsComponent } from './subjects/subjects.component';
// import { LessonDetailsComponent } from './lesson-details/lesson-details.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HelpPageComponent } from './help-page/help-page.component';
// import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule } from '@angular/forms';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { PresentationSlidesComponent } from './presentation-slides/presentation-slides.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './auth.guard';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { BloggerListComponent } from './blogger-list/blogger-list.component';
import { BlogsPageComponent } from './blogs-page/blogs-page.component';
import { MapComponent } from './map/map.component';
import { SafePipe } from './safe.pipe';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { WhtsappcontactComponent } from './whtsappcontact/whtsappcontact.component';
import { HttpClientModule } from '@angular/common/http';
import { StudentPageComponent } from './student-page/student-page.component';
import { SEOService } from './seo-service.service';
import { BootcampComponent } from './bootcamp/bootcamp.component';
import { SendmailComponent } from './sendmail/sendmail.component';
import { TechComponent } from './tech/tech.component';
import { JourneyComponent } from './journey/journey.component';
import { StudentsComponent } from './students/students.component';
import { MentorsComponent } from './mentors/mentors.component';
import { VideosComponent } from './videos/videos.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { GalleryPageComponent } from './gallery-page/gallery-page.component';
import { InstagramDOMService } from './gallery-page/insta.service';
import { OnlineComponent } from './online-page/online.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',

  loop: false,
  init: true,
  autoplay: false,
  pagination: {
    el: '.swiper-pagination',
    // type: '',
    clickable: true,
  },
  navigation: true,
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
};

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    pathMatch: 'full',
    data: {
      title: 'Full Stack Developer Course - React JS, Node JS, MongoDB | MERN Stack',
      description: 'Learn Reactjs, Nodejs, MongoDb, Express, Git, Cloud hosting, Firebase during Summer Bootcamp 2023 in Jaipur',
      ogUrl: {
        url:'url',
        image:'image',
        description: 'desc',
        title: 'title',
      },
      twitter:{
        card:'url',
        image:'image',
        description: 'desc',
        title: 'title',
      }
    }
  },
  {
    path: 'projects', component: ProjectsPageComponent,
    pathMatch: 'full',
    data: {
      title: 'Projects List - 2019 Summer Bootcamp',
      description: 'Learn React, Angular, Nodejs, Python or Machine Learning during Summer Bootcamp 2023 in Jaipur',
      ogUrl: 'your og url'
    }
  },
  {
    path: 'online', component: OnlineComponent,
    pathMatch: 'full',
    data: {
      title: 'Online Course - 2023 Summer Bootcamp',
      description: 'Learn React, Angular, Nodejs, Python or Machine Learning during Summer Bootcamp 2023 in Jaipur',
      ogUrl: 'your og url'
    }
  },
  {
    path: 'students', component: StudentPageComponent,
    pathMatch: 'full',
    data: {
      title: 'Student List - YouStart Summer Bootcamp',
      description: 'Learn React, Angular, Nodejs, Python or Machine Learning during Summer Bootcamp 2023 in Jaipur',
      ogUrl: 'your og url'
    }
  },
  {
    path: 'blog', component: BloggerListComponent,
    pathMatch: 'full',
    data: {
      title: 'Blog',
      description: 'YouStart Blog',
      ogUrl: 'your og url'
    }
  },
  { path: 'blog/:link', component: BlogsPageComponent },
  {
    path: 'help', component: HelpPageComponent,
    data: {
      title: 'FAQs',
      description: 'Frequently Asked Question about Youstart Bootcamp in Full Stack and Machine Learning',
      ogUrl: 'your og url'
    }
  },
  {
    path: 'gallery', component: GalleryPageComponent,
    data: {
      title: 'Gallery',
      description: 'Gallery of last bootcamps',
      ogUrl: 'your og url'
    }
  }];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    CardsComponent,
    // FirstPageComponent,
    HomeComponent,
    // LessonListComponent,
    // SubjectsComponent,
    // LessonDetailsComponent,
    HelpPageComponent,
    FeedbackComponent,
    // PresentationSlidesComponent,
    BloggerListComponent,
    BlogsPageComponent,
    MapComponent,
    SafePipe,
    EnquiryComponent,
    WhtsappcontactComponent,
    StudentPageComponent,
    BootcampComponent,
    SendmailComponent,
    TechComponent,
    JourneyComponent,
    StudentsComponent,
    MentorsComponent,
    VideosComponent,
    ProjectsComponent,
    ProjectsPageComponent,
    GalleryPageComponent,
    OnlineComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    // BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    // CarouselModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(routes),
    // Ng2PageScrollModule.forRoot(),
    // RatingModule.forRoot(),
    // SwiperModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    HttpClientModule
  ],
  providers: [AuthGuard,
    RecordsDataService,
    SEOService,
    InstagramDOMService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
