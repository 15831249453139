import { Component, OnInit } from '@angular/core';
import { RecordsDataService } from '../records-data.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

interface feed {
  Email: string;
  Description: string;
}
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
  
})
export class FeedbackComponent implements OnInit {
  description: string;
  email: string;
  angForm: FormGroup;
  message: any;


  constructor(public myFirstService: RecordsDataService, public httpClient: HttpClient, private afs: AngularFirestore, private toastr: ToastrService, private fb: FormBuilder) {
    this.createForm();

  }
  feedbackcollection: AngularFirestoreCollection<any> = this.afs.collection('feedback');
  ngOnInit() {

  }

  createForm() {
    this.angForm = this.fb.group({
      email: ['', Validators.required],
      description: ['', Validators.required],

    });
  }

  onSubmit() {
    const userRef = this.feedbackcollection;
    const data: feed = {
      Email: this.email,
      Description: this.description
    }
    userRef.add(data);
    this.toastr.success("We will get back to you soon", "Submitted Successfully", {
      timeOut: 1500
    });
  };

 
}
