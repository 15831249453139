import { Component, OnInit } from '@angular/core';
import { RecordsDataService } from '../records-data.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

interface feed {
  Name: string;
  Description: string;
  // email: string;
  Email: string;
  // Course : string;
}
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  name: string;
  description: string;
  email: string;
  Email: string;
  //  course: string;
  angForm: FormGroup;
  innerwidth: any;


  constructor(public myFirstService: RecordsDataService, private toastr: ToastrService, private afs: AngularFirestore, private fb: FormBuilder) {
    this.createForm();

  }
  coursedoubt: AngularFirestoreCollection<any> = this.afs.collection('courseenquiry');
  ngOnInit() {
    this.innerwidth = window.innerWidth;
    //console.log(this.innerwidth);
  }


  onNavigate(item:string){
    switch(item){
    case 'fullstack':
          window.open("http://bit.ly/2019SummerBootcampFullstack", "_blank");
          break;
    case 'ml':
          window.open("http://bit.ly/2019SummerBootcampMl", "_blank");
          break;
    case 'calendly':
          window.open("https://calendly.com/youstart/interact-with-course-instructor/01-07-2019", "_blank");
          break;
    case 'meetup':
          window.open("https://www.meetup.com/Youstart-Jaipur-Career-Talk/events/259152189/", "_blank");
          break;      
    }
   }

  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      //  course: ['Please select the course',Validators.required],
      description: ['', Validators.required],

    });
  }


  onSubmit() {
    // //console.log(this.angForm);
    const userRef = this.coursedoubt;
    const data: feed = {
      Name: this.name,
      Email: this.email,
       Description: this.description,
     }
    userRef.add(data);
    this.toastr.success("We will get back to you soon", "Submitted Successfully", {
      timeOut: 1500
    });
  };

}
