import { Component, OnInit } from '@angular/core';
import {  Router,ChildActivationEnd} from '@angular/router';
import { RecordsDataService } from '../records-data.service';
import { map, filter, mergeMap,take } from 'rxjs/operators';
import { SEOService } from '../seo-service.service';

@Component({
  selector: 'app-blogger-list',
  templateUrl: './blogger-list.component.html',
  styleUrls: ['./blogger-list.component.css']
})
export class BloggerListComponent implements OnInit {
homeData = {}; 
  constructor(public route:Router, public myFirstService: RecordsDataService,private _seoService:SEOService) { 
    this.route.events.pipe(
      filter(event => event instanceof ChildActivationEnd),
      take(1),
      map((e:ChildActivationEnd)=>e.snapshot.firstChild.data)
    ).subscribe((event)=>{
      this._seoService.updateTitle(event['title']);
      this._seoService.updateOgUrl(event['ogUrl']);
      //Updating Description tag dynamically with title
      this._seoService.updateDescription(event['title'] + event['description'])
      
      })
  }

  ngOnInit() {

    
    this.homeData = this.myFirstService.getData().Alumni.Details;
    //console.log(this.homeData , "experiment")
  }

}
