import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  latitude: number = 51.678418;
  longitude: number = 7.809007;
  onChoseLocation(event){
  //console.log(event);
  this.latitude = event.coords.lat;
  this.longitude =event.coords.lng;
  }
  constructor() { }

  ngOnInit() {
  }

}
