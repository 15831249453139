import { Component, OnInit } from '@angular/core';
import {RecordsDataService } from '../records-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public myFirstService: RecordsDataService) { }
  isLoggedIn() {
    return this.myFirstService.isLoggedIn();
  }
  ngOnInit() {
  }

}
