import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-sendmail',
  templateUrl: './sendmail.component.html',
  styleUrls: ['./sendmail.component.css']
})
export class SendmailComponent implements OnInit {
  public fullstackangForm;
  public emailfullstack;

  constructor(
    private httpClient: HttpClient,
    private fb: FormBuilder,
     private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.fullstackangForm = this.fb.group({
      emailfullstack: ['', Validators.required],
     });
  }

  gotoPdf() {

    this.httpClient.post('https://us-central1-youstartlabs-52d8e.cloudfunctions.net/sendEmail',
      {
        'email':  this.emailfullstack
      },
    ).subscribe(
        data => {
          this.toastr.success('Email sent successfully', 'Check your email', {
            timeOut: 1500,
            positionClass:'toast-bottom-left'
          });
        },
        error => {
          // console.log("Error", error);
          this.toastr.error('Please try again later', 'Sorry we are having some server issues', {
            timeOut: 3000
          });
        }
      );
      this.fullstackangForm.reset();

  }

}
