import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(og:any) {
    this.meta.updateTag({ name: 'og:title', content: og.title });
    this.meta.updateTag({ name: 'og:url', content: og.url });
    this.meta.updateTag({ name: 'og:description', content: og.description });
    this.meta.updateTag({ name: 'og:image', content: og.image });
  }

  updateTwitterMetaTags(twitter:any) {
    this.meta.updateTag({ name: 'twitter:title', content: twitter.title });
    this.meta.updateTag({ name: 'twitter:card', content: twitter.card });
    this.meta.updateTag({ name: 'twitter:description', content: twitter.description });
    this.meta.updateTag({ name: 'twitter:image', content: twitter.image });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

}