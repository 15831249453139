import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ChildActivationEnd, Router } from '@angular/router';
import { map, filter, mergeMap, take } from 'rxjs/operators';
import { SEOService } from './seo-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';


  constructor(public router: Router,
     public activatedRoute: ActivatedRoute,
      public _seoService: SEOService) {

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        if (evt.url.indexOf('#') < 0) {
          window.scrollTo(0, 0);
        }
    });

  this.router.events.pipe(
      filter(event => event instanceof ChildActivationEnd),
      take(1),
      map((e: ChildActivationEnd) => e.snapshot.firstChild.data)
    )
    .subscribe((event) => {
      console.log(event);
      this._seoService.updateTitle(event['title']);
      this._seoService.updateOgUrl(event['ogUrl']);
      // Updating Description tag dynamically with title
      this._seoService.updateDescription(event['title'] + event['description']);
});


}
}