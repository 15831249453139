import { Component, OnInit } from '@angular/core';
import { RecordsDataService } from '../records-data.service';

@Component({
  selector: 'app-student-page',
  templateUrl: './student-page.component.html',
  styleUrls: ['./student-page.component.css']
})
export class StudentPageComponent implements OnInit {
  constructor(public recordService: RecordsDataService) {
  }

  ngOnInit() {
  }

}
