import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  providerId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RecordsDataService {
  private studentCollection: AngularFirestoreCollection<any> = this.firestore.collection('students')
  private mentorCollection: AngularFirestoreCollection<any> = this.firestore.collection('mentors')
  homeData: any;
  lessons: any;
  subjectData: any;
  public Course: any = [];
  public Subject: any = [];
  public Alumni: any = [];
  public Help: any = [];
  public Ratings: any = [];
  public User: Observable<User>;
  public ListBy: any = [];
  public sildeData: any = [];
  public BlogsData: any = [];
  public studentsData: any = [];

  public dataStudents = {}
  public mentors: {}[];


  constructor(private firestore: AngularFirestore, private afAuth: AngularFireAuth, private router: Router, ) {
    this.getSubjects();
    this.getAlumni();
    this.getRatings();
    this.getHelp();
    this.getListByPurpose();
    this.getSlidesData();
    this.getBlogs();
    this.getCourseDetails();
    this.studentDetails();
    this.User = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.firestore.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null)
        }
      })
    )
  }


  signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider()
    return this.oAuthLogin(provider);
  }


  login() {
    const provider = new auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }

  public oAuthLogin(provider: any) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        //console.log(credential)
        if (credential) {
          this.router.navigate(['/firstPage']);
        } else {
          this.router.navigate(['/home']);
        }
        this.updateUserData(credential)
      })
  }

  public updateUserData(credential: any) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${credential.user.uid}`);
    const data: User = {
      uid: credential.user.uid,
      email: credential.user.email,
      displayName: credential.user.displayName,
      photoURL: credential.user.photoURL,
      providerId: credential.additionalUserInfo.providerId
    }
    return userRef.set(data, { merge: true });
    //return userRef.set(Object.assign({}, JSON.parse(JSON.stringify(user.providerData[0]))), { merge: true });
  }


  isLoggedIn() {
    if (!this.afAuth.auth.currentUser) {
      return false;
    }
    return true;
  }


  logout() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/home']);
    });
  }

  public getSubjects() {
    return this.firestore.collection('Subjects').valueChanges()
      .subscribe(res => {
        this.Subject = res;
        //console.log(this.Subject);
      });
  }

  public getCourseDetails() {
    return this.firestore.collection('course').valueChanges()
      .subscribe(res => {
        this.Course = res;
        //console.log(this.Course);
      })
  }

  public getSubjectData(Subjectname) {
    return this.firestore.collection('Subjects', ref => ref.where("name", "==", Subjectname)).valueChanges();
  }


  public getRatings() {
    return this.firestore.collection('Ratings').valueChanges()
      .subscribe(res => {
        this.Ratings = res;
        //console.log(this.Ratings);
      });
  }

  public getAlumni() {
    this.firestore.collection('Alumni').valueChanges()
      .subscribe(res => {
        this.Alumni = res;
        //console.log(this.Alumni);
      });
  }


  public getHelp() {
    this.firestore.collection('faq',ref => ref.orderBy('id', 'asc'))
      .valueChanges()
      .subscribe(res => {
        this.Help = res;
        //console.log(this.Help);
      });
  }

  public getLessons(Subject_id) {
    return this.firestore.collection('lessons', ref => ref.where("name", "==", Subject_id).orderBy("id")).valueChanges()
  }


  public getListByPurpose() {
    this.firestore.collection('ListBYPurpose')
      .valueChanges()
      .subscribe(res => {
        this.ListBy = res;
        //console.log(this.ListBy);
      });
  }



  public getLessonData(Lessonname) {
    return this.firestore.collection('lessons', ref => ref.where("link", "==", Lessonname)).valueChanges();
  }

  public getLessonsDetail(Lessondetail) {
    return this.firestore.collection('lessonDetail', ref => ref.where("name", "==", Lessondetail).orderBy("chapter")).valueChanges();
  }


  public getSlidesData() {
    this.firestore.collection('Slides')
      .valueChanges()
      .subscribe(res => {

        this.sildeData = res;
        //console.log(this.sildeData);
      });
  }


  public getSlideMatchData(slideQuery) {
    return this.firestore.collection('Slides', ref => ref.where("link", "==", slideQuery)).valueChanges();

  }

  public getBlogData(BlogQuery) {
    return this.firestore.collection('Blogs', ref => ref.where("slug", "==", BlogQuery)).valueChanges();

  }

  public getBlogs() {
    this.firestore.collection('Blogs', ref => ref.orderBy("TimeStamp", 'desc'))
      .valueChanges()
      .subscribe(res => {

        this.BlogsData = res;
        //console.log(this.BlogsData);
      });
  }
  public studentDetails() {
    // this.dataStudents.studentDetails.Details.forEach(element => {
    //   this.studentCollection.add(element);     
    // });
    // this.mentorList.forEach(element => {
    //   this.mentorCollection.add(element);
    // })
    this.firestore.collection('students').valueChanges()
      .subscribe(res => {
        //console.log('res', res)
        this.studentsData = res
        //console.log(this.studentsData, 'data for students');
      });
    this.firestore.collection('mentors' , ref => ref.orderBy('seq')).valueChanges()
      .subscribe(res => {
        //console.log('res', res)
        this.mentors = res
        //console.log(this.studentsData, 'data for students');
      });
  }


  getData() {
    return this.homeData = {
      // slides presentation data
      'dataSlide': {
        'data1': [{
          'name': 'html and css',
          'image1': 'assets/welcome.png',
          'subjectName': 'What is JavaScript (ES 6)',
          'subjectDesc1': 'In this lesson we aim to create a web page like the figure below.It may seem difficult, but please do not worry.Lets learn and work together with us.',
        }],
        'data2': [{
          'subjectDesc2': ' JavaScript (hereinafter referred to as JS) is essential for Web development. Currently, JS is used on almost all Web sites. ES 2015 (ES 6) to be learned this time is attracting attention in recent years because it is a new specification (version) of JS and code can be written more efficiently than conventional JS..',
          'subjectName': 'What is JavaScript (ES 6)',
          'image1': 'assets/welcome.png',

        }],
        'data7': [{
          'taghead': 'Lets learn JavaScript (ES 6)',
          'tagName': 'Lets run the program',
          'image3': 'assets/consoleresult.png',
          'image2': 'assets/console.png',
          'subjectDesc1': 'In this lesson we aim to create a web page like the figure below.It may seem difficult, but please do not worry.Lets learn and work together with us.',
        }],
        'data3': [{
          'tagName': 'Lets run the program',
          'taghead': 'Lets learn JavaScript (ES 6)',
          'image4': 'assets/a.svg',
          'image5': 'assets/2.jpg',
          'subjectDesc2': ' JavaScript (hereinafter referred to as JS) is essential for Web development. Currently, JS is used on almost all Web sites. ES 2015 (ES 6) to be learned this time is attracting attention in recent years because it is a new specification (version) of JS and code can be written more efficiently than conventional JS..',
        }],
        'data4': [{
          'contentHeading': 'ANGULAR CONTENTS',
          'content1': 'modues',
          'content2': 'directives',
          'content3': 'links',
          'content4': 'routes',
          'content5': 'parameter routs',
          'content6': 'data binding',
          'content7': 'and many more....',
        }],
        'data5': [{
          'image6': 'assets/3.jpg',

        }]
      },
      // Page rating data
      'Ratings': {
        'types': [{
          'user': 'Number of users',
          'total': '4100 People',
          'detailimg': 'assets/a.svg'
        },
        {
          'user': 'Number of users',
          'total': '410000 People',
          'detailimg': 'assets/a.svg'
        },
        {
          'user': 'Number of users',
          'total': '410000 People',
          'detailimg': 'assets/a.svg'
        }
        ]
      },
      'studentDetails': {
        'Details': [{
          "imageUrl": '../../assets/2.jpg',
          "fullname": 'Md shadman ALam',
          "college": 'JNIT',
          "company": 'YOUStart',
          "student_desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta possimus voluptas et consequuntur esse provident sequi suscipit non eum.Incidunt beatae nesciunt accusantium a maiores alias consequunturdoloremque culpa consectetur! Lorem ipsum dolor sit amet consectetur adipisicing elit.Dolor,deserunt.Unde ipsam eaque natus suscipit molestiae soluta minus quae officia ex modi voluptatem et explicabo accusamus aperiam quos, perferendis cumque.",
          "links": [{
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'linkedon@mdsadman96@gmail.com',
            "followers": '151255,15'
          }, {
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'facebook@mdsadman96@gmail.com',
            "followers": '123,456.0'

          }, {
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'github@mdsadman96@gmail.com',
            "followers": '11,458'

          }],
          'research_detail': [{
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }]
        }, {
          "imageUrl": '../../assets/2.jpg',
          "fullname": 'Md shadman ALam',
          "college": 'JNIT',
          "company": 'YOUStart',
          "student_desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta possimus voluptas et consequuntur esse provident sequi suscipit non eum.Incidunt beatae nesciunt accusantium a maiores alias consequunturdoloremque culpa consectetur! Lorem ipsum dolor sit amet consectetur adipisicing elit.Dolor,deserunt.Unde ipsam eaque natus suscipit molestiae soluta minus quae officia ex modi voluptatem et explicabo accusamus aperiam quos, perferendis cumque.",
          "links": [{
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'linkedon@mdsadman96@gmail.com',
            "followers": '151255,15'
          }, {
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'facebook@mdsadman96@gmail.com',
            "followers": '123,456.0'

          }, {
            'linkedIMage': '../../assets/2.jpg',
            "linksocial": 'github@mdsadman96@gmail.com',
            "followers": '11,458'

          }],
          'research_detail': [{
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }]
        }, {
          "imageUrl": '../../assets/2.jpg',
          "fullname": 'Md shadman ALam',
          "college": 'JNIT',
          "company": 'YOUStart',
          "student_desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta possimus voluptas et consequuntur esse provident sequi suscipit non eum.Incidunt beatae nesciunt accusantium a maiores alias consequunturdoloremque culpa consectetur! Lorem ipsum dolor sit amet consectetur adipisicing elit.Dolor,deserunt.Unde ipsam eaque natus suscipit molestiae soluta minus quae officia ex modi voluptatem et explicabo accusamus aperiam quos, perferendis cumque.",
          "links": [{
            'linkedIMage': '../../assets/3.jpg',
            "linksocial": 'linkedon@mdsadman96@gmail.com',
            "followers": '151355,15'
          }, {
            'linkedIMage': '../../assets/3.jpg',
            "linksocial": 'facebook@mdsadman96@gmail.com',
            "followers": '133,456.0'

          },
          ],
          'research_detail': [{
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }, {
            'researchImage': '../../assets/2.jpg',
            'researchLink': 'javascript',
            'researchfollower': 'javascript',
          }]
        }]
      },
      // Ex-interns and developers
      'Alumni': {
        'Details': [{
          'link': 'blogger_1',
          'name': 'Md shadman alam',
          'image': 'assets/1.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#fafa76',
          'quote': 'During his internship at Youstart Labs, he used OpenCV and Unity3D to create a game on augmented reality. He has been participating in ACM ICPC for past 3 consecutive years. His team “gareebCoders” ranked “40” in IIT Kharagpur regional, ACM ICPC 2017. With a rank “383” on CodeChef, he is President of GNU/Linux User Group and Coordinator of RECursion, at his college. Prevously, educator of competitive programming at Unacademy,he is now placed at the gaming company – PlaySimple.'
        },
        {
          'link': 'blogger_2',

          'name': 'Md shadman alam',
          'image': 'assets/2.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#fafa09',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_3',

          'name': 'Md shadman alam',
          'image': 'assets/3.jpg',
          'color': 'purple',
          'duration': 'July2018-Oct2019',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_4',

          'name': 'Md shadman alam',
          'image': 'assets/1.jpg',
          'duration': 'July2018-Oct2019',
          'color': 'lightpink',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_5',

          'name': 'Md shadman alam',
          'image': 'assets/2.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#71a5f3',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_6',

          'name': 'Md shadman alam',
          'image': 'assets/3.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#ff8db3',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_7',

          'name': 'Md shadman alam',
          'image': 'assets/3.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#e4df45',
          'quote': 'Awesome Experience for career'
        }, {
          'link': 'blogger_8',
          'name': 'Md shadman alam',
          'image': 'assets/1.jpg',
          'duration': 'July2018-Oct2019',
          'color': '#4ccfc9',
          'quote': 'Awesome Experience for career'
        }
        ]
      },
      'Blogs': {
        'blogger_1': {
          'name': 'javascript',
          'duration': '551- 5515',
          'description': 'this is a demo test for dynamic routing for blog posts',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_2': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_3': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_4': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_5': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_6': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_7': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        },
        'blogger_8': {
          'name': 'javascript',
          'duration': 'javascript',
          'description': 'javascript',
          'Blog_detail': [{
            'image': 'assets/1.jpg',
            'head': 'javascript',
            'taghead': 'javascript',
          },]
        }

      },


      // Cards about subjects tought
      'cards': {
        'Subjects': [{
          'id': 1,
          'name': 'HTML & CSS',
          'link': 'HTML_CSS',
          'image': 'assets/a.svg',
          'color': '#4ccfc9',
          'text': {
            'name': 'HTML & CSS',
            'lessons': 'Total no of lessons is 9'
          }
        }, {
          'id': 2,
          'link': 'javascript',
          'name': 'Javascript',
          'image': 'assets/a.svg',
          'color': '#a0c3f7',
          'text': {
            'name': 'JAVASCRIPT',
            'lessons': 'Total no of lessons is 14'
          }
        }, {
          'id': 3,
          'name': 'Java',
          'image': 'assets/a.svg',
          'color': '#e4df45',
          'link': 'java',
          'text': {
            'name': 'JAVA',
            'lessons': 'Total no of lessons is 20'
          }
        }, {
          'id': 4,
          'name': 'Ruby',
          'image': 'assets/a.svg',
          'color': '#ff4c5e',
          'link': 'Ruby',
          'text': {
            'name': 'RUBY',
            'lessons': 'Total no of lessons is 12'
          }
        }, {
          'id': 5,
          'name': 'Ruby on Rails',
          'image': 'assets/a.svg',
          'color': '#ff8db3',
          'link': 'Ruby on Rails',
          'text': {
            'name': 'RUBY ON RAILS',
            'lessons': 'Total no of lessons is 8'
          }
        }, {
          'id': 6,
          'name': 'PHP',
          'image': 'assets/a.svg',
          'color': '#71a5f3',
          'link': 'PHP',
          'text': {
            'name': 'PHP',
            'lessons': 'Total no of lessons is 9'
          }
        }, {
          'id': 7,
          'name': 'PYTHON',
          'image': 'assets/a.svg',
          'color': '#ff4c5e',
          'link': 'python',
          'text': {
            'name': 'PYTHON',
            'lessons': 'Total no of lessons is 6'
          }
        }, {
          'id': 8,
          'name': 'AngularJS',
          'image': 'assets/a.svg',
          'color': '#71a5f3',
          'link': 'angularJS',
          'text': {
            'name': 'ANGULARJS',
            'lessons': 'Total no of lessons is 10'
          }
        }, {
          'id': 9,
          'name': 'NodeJs',
          'image': 'assets/a.svg',
          'color': '#71a5f6',
          'link': 'nodeJs',
          'text': {
            'name': 'NODEJS',
            'lessons': 'Total no of lessons is 9'
          }
        }, {
          'id': 10,
          'name': 'MongoDb',
          'image': 'assets/a.svg',
          'color': '#71c5f4',
          'link': 'mongoDb',
          'text': {
            'name': 'MONGODB',
            'lessons': 'Total no of lessons is 12'
          }
        }]
      },
      // Subjects and details
      'cardDetails': {
        'Cards': [{
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'Ruby',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'buttonlink': 'HTML_CSS',

          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'buttonlink': 'javascript',

          },


          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },

          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'javascript',
          'Buttons': [


            {
              'name': 'Ruby',
              'color': 'pink',
              'lessons': 'Ruby',
              'buttonlink': 'Ruby',

            },


          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },

          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'HTML_CSS',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'lessons': '',
            'buttonlink': 'HTML_CSS',
          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'lessons': '',
            'buttonlink': 'javascript',

          },
          {
            'name': 'Ruby',
            'color': 'pink',
            'lessons': 'Ruby',
            'buttonlink': 'Ruby'
          },


          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },

          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'RubyonRails',
          'Buttons': [
            {
              'name': 'python',
              'color': 'pink',
              'lessons': 'Ruby',
              'buttonlink': 'python'
            },
            {
              'name': 'Ruby',
              'color': 'blue',
              'lessons': 'Ruby',
              'buttonlink': 'angularJS'

            }

          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },

          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'PHP',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'lessons': ''
          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'lessons': 'Ruby'
          },
          {
            'name': 'Ruby',
            'color': 'pink',
            'lessons': ''
          },


          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },
          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'python',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'lessons': '',
            'link': 'python'
          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'lessons': '',
            'link': 'javascript'
          },
          {
            'name': 'Ruby',
            'color': 'pink',
            'lessons': '',
            'link': 'Ruby'
          },

          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },

          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'angularJS',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'lessons': ''
          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'lessons': ''
          },
          {
            'name': 'Ruby',
            'color': 'pink',
            'lessons': ''
          },
          {
            'name': 'Ruby',
            'color': 'Ruby',
            'lessons': 'Ruby'
          }
          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },
          ]
        },
        {
          'button': 'HTml ',
          'name': 'Ruby',
          'image': 'assets/ruby.svg',
          'color': '#ff4c5e',
          'title': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'description': 'Server-side language which is easy to understand intuitively, boasts high productivity',
          'link': 'mongoDb',
          'Buttons': [{
            'name': 'Html & css',
            'color': 'red',
            'lessons': ''
          },
          {
            'name': 'Javascript',
            'color': 'blue',
            'lessons': ''
          },
          {
            'name': 'Ruby',
            'color': 'pink',
            'lessons': 'Ruby'
          },

          ],
          'Footer': [{
            'Totallessons': '9 lessons in total',
            'imageRating': '***'
          },
          ]
        }
        ]
      },
      // subjects and lessons data
      // 'subjects': {
      //   'Javascript': [{
      //     'id': '1',
      //     'link': 'htmljs',
      //     'SubjectName': 'javascript',
      //     'heading': 'HTML & CSS',
      //     'Subheading': 'Languages used to build and design the appearances of your websites.',
      //     'Description': 'Languages used to build and design the appearances of your websites.',
      //     'lessons': [{
      //       'Tagname': 'HTML & CSS Beginner',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
      //       'LessonDuration': ' Completion Hours 3h 50m',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'HTML & CSS Intermediate',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'Html & CSS Advanced',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'Html & CSS Advanced',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     }
      //     ]
      //   },
      //   ],
      //   'Java': [{
      //     'id': '1',
      //     'link': 'htmlcss',
      //     'SubjectName': 'javascript',
      //     'heading': 'HTML & CSS',
      //     'Subheading': 'Languages used to build and design the appearances of your websites.',
      //     'Description': 'Languages used to build and design the appearances of your websites.',
      //     'lessons': [{
      //       'Tagname': 'HTML & CSS Beginner',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
      //       'LessonDuration': ' Completion Hours 3h 50m',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'HTML & CSS Intermediate',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'Html & CSS Advanced',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     },
      //     {
      //       'Tagname': 'Html & CSS Advanced',
      //       'LessonHading': 'HTML & CSS',
      //       'LessonDescription': 'HTML & CSS',
      //       'LessonDuration': 'HTML & CSS',
      //       'image': 'assets/2.jpg',
      //     }
      //     ]
      //   },
      //   ]
      // }
      'subjects': {
        'javascript': {
          'id': 1,
          'name': 'javascript',
          'SubjectName': 'javascript',
          'heading': 'javascript',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'javascript Beginner',
            'LessonHading': 'javascript',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javascriptBeginner'
          },
          {
            'Tagname': 'javascript Intermediate',
            'LessonHading': 'javascript',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javascriptIntermediate'
          },
          {
            'Tagname': 'javascript Advanced',
            'LessonHading': 'javascript',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javascriptAdvanced'
          },
          {
            'Tagname': 'javascript Advanced',
            'LessonHading': 'javascript',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javascriptAdvanced'

          }
          ]


        },
        'java': {
          'name': 'java',

          'id': '2',
          'SubjectName': 'java',
          'heading': 'Java',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'Java Beginner',
            'LessonHading': 'Java',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javaBeginner'

          },
          {
            'Tagname': 'Java Intermediate',
            'LessonHading': 'Java',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javaBeginner'

          },
          {
            'Tagname': 'Java Advanced',
            'LessonHading': 'Java',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javaBeginner'

          },
          {
            'Tagname': 'Java Advanced',
            'LessonHading': 'Java',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'javaBeginner'

          }
          ]

        },
        'HTML_CSS': {
          'name': 'HTML & CSS',
          'id': '2',
          'SubjectName': 'HTML & CSS',
          'heading': 'HTML & CSS',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'HTML & CSS Beginner',
            'LessonHading': 'HTML & CSS',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'HTML_CSSBeginner'

          },
          {
            'Tagname': 'HTML & CSS Intermediate',
            'LessonHading': 'HTML & CSS',
            'LessonDescription': 'HTML & CSS',
            'LessonDuration': 'Java',
            'image': 'assets/2.jpg',
            'link': 'HTML_CSSBeginner'

          },
          {
            'Tagname': 'HTML & CSS Advanced',
            'LessonHading': 'HTML & CSS',
            'LessonDescription': 'HTML & CSS',
            'LessonDuration': 'HTML & CSS',
            'image': 'assets/2.jpg',
            'link': 'HTML_CSSBeginner'

          },
          {
            'Tagname': 'HTML & CSS  Advanced',
            'LessonHading': 'HTML & CSS',
            'LessonDescription': 'HTML & CSS',
            'LessonDuration': 'HTML & CSS',
            'image': 'assets/2.jpg',
            'link': 'HTML_CSSBeginner'
          }
          ]
        },
        'Ruby': {
          'name': 'Ruby',
          'id': '2',
          'SubjectName': 'Ruby',
          'heading': 'Ruby',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'Ruby Beginner',
            'LessonHading': 'Ruby',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'RubyBeginner'

          },
          {
            'Tagname': 'Ruby Intermediate',
            'LessonHading': 'Ruby',
            'LessonDescription': 'Ruby',
            'LessonDuration': 'Ruby',
            'image': 'assets/2.jpg',
            'link': 'RubyBeginner'

          },
          {
            'Tagname': 'Ruby Advanced',
            'LessonHading': 'Ruby',
            'LessonDescription': 'Ruby',
            'LessonDuration': 'Ruby',
            'image': 'assets/2.jpg',
            'link': 'RubyBeginner'

          },
          {
            'Tagname': 'Ruby Advanced',
            'LessonHading': 'Ruby',
            'LessonDescription': 'Ruby',
            'LessonDuration': 'Ruby',
            'image': 'assets/2.jpg',
            'link': 'RubyBeginner'

          }
          ]
        },
        'RubyonRails': {
          'name': 'Ruby on Rails',
          'id': '2',
          'SubjectName': 'Ruby on Rails',
          'heading': 'Ruby on Rails',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'Ruby on Rails Beginner',
            'LessonHading': 'Ruby on Rails',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'RubyRubyonRailsBeginner'

          },
          {
            'Tagname': 'Ruby on Rails Intermediate',
            'LessonHading': 'Ruby on Rails',
            'LessonDescription': 'Ruby on Rails',
            'LessonDuration': 'Ruby on Rails',
            'image': 'assets/2.jpg',
            'link': 'RubyRubyonRailsBeginner'

          },
          {
            'Tagname': 'Ruby on Rails Advanced',
            'LessonHading': 'Ruby on Rails',
            'LessonDescription': 'Ruby on Rails',
            'LessonDuration': 'Ruby on Rails',
            'image': 'assets/2.jpg',
            'link': 'RubyRubyonRailsBeginner'

          },
          {
            'Tagname': 'Ruby on Rails Advanced',
            'LessonHading': 'Ruby on Rails',
            'LessonDescription': 'Ruby on Rails',
            'LessonDuration': 'Ruby on Rails',
            'image': 'assets/2.jpg',
            'link': 'RubyRubyonRailsBeginner'

          }
          ]

        },
        'PHP': {
          'name': 'PHP',

          'id': '2',
          'SubjectName': 'PHP',
          'heading': 'PHP',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'PHP Beginner',
            'LessonHading': 'PHP',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'PHPBeginner'

          },
          {
            'Tagname': 'PHP Intermediate',
            'LessonHading': 'PHP',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'PHPBeginner'

          },
          {
            'Tagname': 'PHP Advanced',
            'LessonHading': 'PHP',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'PHPBeginner'

          },
          {
            'Tagname': 'PHP Advanced',
            'LessonHading': 'PHP',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'PHPBeginner'

          }
          ]

        },
        'python': {
          'name': 'PYTHON',
          'id': '2',
          'SubjectName': 'python',
          'heading': 'python',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'python Beginner',
            'LessonHading': 'python',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'pythonBeginner'

          },
          {
            'Tagname': 'python Intermediate',
            'LessonHading': 'python',
            'LessonDescription': 'python',
            'LessonDuration': 'python',
            'image': 'assets/2.jpg',
            'link': 'pythonBeginner'

          },
          {
            'Tagname': 'python Advanced',
            'LessonHading': 'python',
            'LessonDescription': 'python',
            'LessonDuration': 'python',
            'image': 'assets/2.jpg',
            'link': 'pythonBeginner'

          },
          {
            'Tagname': 'python Advanced',
            'LessonHading': 'python',
            'LessonDescription': 'python',
            'LessonDuration': 'python',
            'image': 'assets/2.jpg',
            'link': 'pythonBeginner'
          }
          ]

        },
        'angularJS': {
          'name': 'AngularJS',

          'id': '2',
          'SubjectName': 'AngularJS',
          'heading': 'AngularJS',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'AngularJS Beginner',
            'LessonHading': 'AngularJS',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'angularJSBeginner'

          },
          {
            'Tagname': 'AngularJS Intermediate',
            'LessonHading': 'AngularJS',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'angularJSBeginner'

          },
          {
            'Tagname': 'AngularJS Advanced',
            'LessonHading': 'AngularJS',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg', 'link': 'angularJSBeginner'

          },
          {
            'Tagname': 'AngularJS Advanced',
            'LessonHading': 'AngularJS',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'angularJSBeginner'

          }
          ]
        },
        'mongoDb': {
          'name': 'MongoDb',

          'id': '2',
          'SubjectName': 'MongoDb',
          'heading': 'MongoDb',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'MongoDb Beginner',
            'LessonHading': 'MongoDb',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'mongoDbBeginner'

          },
          {
            'Tagname': 'MongoDb Intermediate',
            'LessonHading': 'MongoDb',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'mongoDbBeginner'
          },
          {
            'Tagname': 'MongoDb Advanced',
            'LessonHading': 'Java',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg',
            'link': 'mongoDbBeginner'
          },
          {
            'Tagname': 'MongoDb Advanced',
            'LessonHading': 'MongoDb',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg', 'link': 'mongoDbBeginner'
          },
          ]
        },
        'nodeJs': {
          'name': 'NodeJs',

          'id': '2',
          'SubjectName': 'NodeJs',
          'heading': 'NodeJs',
          'Subheading': 'Languages used to build and design the appearances of your websites.',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'lessons': [{
            'Tagname': 'NodeJs Beginner',
            'LessonHading': 'NodeJs',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'LessonDuration': ' Completion Hours 3h 50m',
            'image': 'assets/2.jpg', 'link': 'nodeJsBeginner'
          },
          {
            'Tagname': 'NodeJs Intermediate',
            'LessonHading': 'NodeJs',
            'LessonDescription': 'NodeJs',
            'LessonDuration': 'NodeJs',
            'image': 'assets/2.jpg', 'link': 'nodeJsBeginner'
          },
          {
            'Tagname': 'NodeJs Advanced',
            'LessonHading': 'NodeJs',
            'LessonDescription': 'NodeJs',
            'LessonDuration': 'NodeJs',
            'image': 'assets/2.jpg', 'link': 'nodeJsBeginner'
          },
          {
            'Tagname': 'NodeJs Advanced',
            'LessonHading': 'NodeJs',
            'LessonDescription': 'NodeJs',
            'LessonDuration': 'NodeJs',
            'image': 'assets/2.jpg', 'link': 'nodeJsBeginner'
          }
          ]
        }
      },

      'lessonList': {
        'javascriptBeginner': {
          'start': 'Learning list',
          'name': 'javascript Beginner',
          'heading': 'Learning course',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'LessonDuration': ' Completion Hours 3h 50m',
          'image': 'assets/a.svg',
          'lessonsListDetails': [{
            'loc': 'page1',
            'heading': ' Lets touch HTML!',
            'LessonDescription': 'Learn the role and writing style of HTML.',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page2',
            'heading': 'javascript Beginner',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page3',
            'heading': 'javascript Beginner',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page4',
            'heading': 'javascript Beginner',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          }
          ]
        },
        'javascriptIntermediate': {
          'start': 'Learning list',
          'name': 'javascript  Intermediate',
          'heading': 'Learning course',
          'Description': 'Languages used to build and design the appearances of your websites.',
          'LessonDuration': ' Completion Hours 3h 50m',
          'image': 'assets/a.svg',
          'lessonsListDetails': [{
            'loc': 'page1',
            'heading': ' Lets touch HTML!',
            'LessonDescription': 'Learn the role and writing style of HTML.',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page2',
            'heading': 'javascript Intermediate',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page3',
            'heading': 'javascript Intermediate',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          },
          {
            'loc': 'page4',
            'heading': 'javascript Intermediate',
            'LessonDescription': 'The appearance of the WEB page is made by the language of HTML, CSSs',
            'content1': ' Lets touch HTML!',
            'content2': 'Headings and paragraphs',
            'content3': 'Link',
            'content4': 'image',
            'content5': 'list'
          }
          ]
        },
      }
    };
  }
}
