import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-whtsappcontact',
  templateUrl: './whtsappcontact.component.html',
  styleUrls: ['./whtsappcontact.component.css']
})
export class WhtsappcontactComponent implements OnInit {
  message: string;
  queryForm: FormGroup;

  constructor(public httpClient: HttpClient, private fb: FormBuilder) { }

  ngOnInit() {
  }

  sendToSlack() {
    this.httpClient.post("https://hooks.slack.com/services/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",
      { "text": this.message },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; application/json' }
      }
    ).subscribe(
        data => {
          //console.log("POST Request is successful ", data);
        },
        error => {
          //console.log("Error", error);
        }
      );
  }

  createForm() {
    this.queryForm = this.fb.group({
      message: ['', Validators.required],
    });
  }
}
