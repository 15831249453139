import { Component, OnInit } from '@angular/core';
import {RecordsDataService } from '../records-data.service';
import {  Router,ChildActivationEnd} from '@angular/router';
import { map, filter, mergeMap,take } from 'rxjs/operators';
import { SEOService } from '../seo-service.service';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.css']
})
export class HelpPageComponent implements OnInit {

  constructor(public myFirstService: RecordsDataService,private route:Router,private _seoService:SEOService) { 

    this.route.events.pipe(
      filter(event => event instanceof ChildActivationEnd),
      take(1),
      map((e:ChildActivationEnd)=>e.snapshot.firstChild.data)
    ).subscribe((event)=>{
      this._seoService.updateTitle(event['title']);
      this._seoService.updateOgUrl(event['ogUrl']);
      //Updating Description tag dynamically with title
      this._seoService.updateDescription(event['title'] + event['description'])
      
      })
  }

  ngOnInit() {
  }

}
