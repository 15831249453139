import { Component, OnInit, AfterViewChecked } from '@angular/core';


interface feed {
  email: string;
  Email: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(

        ) {


   }

  ngOnInit() {

  }




}
