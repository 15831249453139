import { Component, OnInit, Input } from '@angular/core';
import {RecordsDataService } from '../records-data.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  @Input() cardData: any;
  @Input() isButton: boolean;
  show(){
  }
  constructor( public myFirstService: RecordsDataService) {
   
  }


  ngOnInit() {
  }

}
